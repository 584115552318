import React from "react";
import GeneralAnchor from "../general-anchor/general-anchor.component";

const CalculatorBox = ({ title, links = [], color = "#3E505D" }) => (
  <div className="calculator-box col" style={{ backgroundColor: color }}>
    <h2 className="block-title">{title}</h2>
    {links.length ? (
      <ul>
        {links.map((linkProps) => (
          <React.Fragment key={linkProps.title}>
            <li>
              <GeneralAnchor additionalClass="calculator-box__anchor" {...linkProps} isLink={true}>
                {linkProps.title}
                <span></span>
              </GeneralAnchor>
            </li>
            {linkProps.desc ? <p className="calculator-box__description">{linkProps.desc}</p> : null}
          </React.Fragment>
        ))}
      </ul>
    ) : null}
  </div>
);

export default CalculatorBox;
